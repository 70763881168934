import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "@components/layout";
import Seo from "@components/seo";

import TopNav from "@components/topNav";
import Footer from "@components/footer";

import { IoPlay, IoPause } from "react-icons/io5";
import { CheckIcon } from "@heroicons/react/outline";

import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  SaveAsIcon,
} from "@heroicons/react/outline";

const actions = [
  {
    title: "Evidence-based",
    href: "#",
    icon: ClockIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    description:
      "We are committed to providing reliable, accurate, and high-quality information on HIV and sexual health. We use the latest evidence to inform our content and programmatic priorities.",
  },
  {
    title: "Responsive",
    href: "#",
    icon: BadgeCheckIcon,
    iconForeground: "text-pink-500",
    iconBackground: "bg-pink-50",
    description:
      "We adapt our approach to respond to changing trends in the HIV epidemic and in how our target audiences access and consume information.",
  },
  {
    title: "Trustworthy",
    href: "#",
    icon: UsersIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description:
      "We have more than 30 years’ experience of working in the HIV response. We are recognised and accredited as a provider of accurate and reliable information.",
  },
  {
    title: "Creative",
    href: "#",
    icon: CashIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
    description:
      "We understand the power of new approaches to bring about change. We focus on solutions and think creatively to continually improve our work and bring new insights to the sector.",
  },
  {
    title: "People-oriented",
    href: "#",
    icon: ReceiptRefundIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
    description:
      "We put people at the centre of what we do and how we do it – supporting, valuing, engaging and involving them. We aim to be accessible, inclusive and friendly in everything we do.",
  },
  {
    title: "Collaborative",
    href: "#",
    icon: AcademicCapIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
    description:
      "We believe by working in partnership we can pool skills and resources, share learning, and have greater impact.",
  },
  {
    title: "Sex-positive",
    href: "#",
    icon: SaveAsIcon,
    iconForeground: "text-gray-700",
    iconBackground: "bg-gray-50",
    description:
      "We believe in a sex-positive approach to sexual health and rights that recognises and celebrates sexual pleasure and sexual diversity, prioritises personal agency, and is free of judgment.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    name: "Evidence-based",
    description:
      "We are committed to providing reliable, accurate, and high-quality information on HIV and sexual health. We use the latest evidence to inform our content and programmatic priorities.",
  },
  {
    name: "Responsive",
    description:
      "We adapt our approach to respond to changing trends in the HIV epidemic and in how our target audiences access and consume information.",
  },
  {
    name: "Trustworthy",
    description:
      "We have more than 30 years’ experience of working in the HIV response. We are recognised and accredited as a provider of accurate and reliable information.",
  },
  {
    name: "Creative",
    description:
      "We understand the power of new approaches to bring about change. We focus on solutions and think creatively to continually improve our work and bring new insights to the sector.",
  },
  {
    name: "People-oriented",
    description:
      "We put people at the centre of what we do and how we do it – supporting, valuing, engaging and involving them. We aim to be accessible, inclusive and friendly in everything we do.",
  },
  {
    name: "Collaborative",
    description:
      "We believe by working in partnership we can pool skills and resources, share learning, and have greater impact.",
  },
  {
    name: "Sex-positive",
    description:
      "We believe in a sex-positive approach to sexual health and rights that recognises and celebrates sexual pleasure and sexual diversity, prioritises personal agency, and is free of judgment.",
  },
];

const About = () => (
  <div>
    <div className="relative bg-midBlue overflow-hidden">
      <div className="max-w-6xl mx-auto">
        <TopNav theme={"midBlue"} />
      </div>
      <div className="mx-auto">
        <main className="mt-10 mx-auto">
          <div className="bg-lightBlue">
            <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="text-center">
                <p className="mt-1 text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl text-midBlue">
                  About us
                </p>
                <p className="max-w-xl mt-5 mx-auto text-2xl text-white">
                  We are an international charity using digital communications
                  to build health literacy on HIV and sexual health.
                </p>
              </div>
            </div>
          </div>

          {/* <div>
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl pt-3 pb-9">
                  Meet our team
                </h2>
              </div> */}
          <div className="">
            <div className="relative py-16 bg-white overflow-hidden">
              <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
              <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                  <h1>
                    {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                      Our Story
                    </span> */}
                    <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      Our Story
                    </span>
                  </h1>
                  <p className="mt-8 text-xl text-gray-500 leading-8">
                    With over 30 years’ experience, we are a trusted provider of
                    accessible, accurate and actionable content and resources
                    that support informed choices. Our work supports global
                    efforts to end AIDS and achieve the Sustainable Development
                    Goal for Health.
                  </p>
                </div>
                <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                  <p>
                    Every year, we engage with millions of individuals,
                    community health workers and primary health practitioners
                    living in areas of greatest need. We use our understanding
                    of HIV and sexual health; our expertise in digital
                    marketing, communications and behaviour change; and our
                    network of partnerships, to increase their knowledge, skills
                    and confidence and their ability to act.
                  </p>

                  <figure>
                    <img
                      className="w-full rounded-lg"
                      src="https://images.unsplash.com/photo-1556740758-90de374c12ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                      alt=""
                      width={1310}
                      height={873}
                    />
                    <figcaption>
                      Put a nice team photo set in Platform9 here
                    </figcaption>
                  </figure>
                  <h2 className="text-center">
                    Our vision, mission and values
                  </h2>

                  <p>
                    Our vision is a world with no new HIV infections, where
                    people make empowered sexual health choices, and where those
                    living with HIV do so with dignity, good health and
                    equality.
                  </p>
                  <p>
                    Our mission is to increase health literacy on HIV and sexual
                    health, among those most affected in areas of greatest need,
                    in order to reduce new infections and improve health and
                    well-being.
                  </p>
                  {/* <blockquote> */}
                  <p>
                    We believe in individuals’ inalienable right to health, and
                    their right to make informed choices around their sexual
                    health. We aim for everything we do, and how we do it, to be
                    underpinned by our set of core values.
                  </p>
                  {/* </blockquote> */}
                </div>
                <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
                  <div className="max-w-3xl mx-auto text-center">
                    <h2 className="pb-6 text-3xl font-bold text-gray-900">
                      Our core values
                    </h2>
                  </div>
                  <div className=" p-6 rounded-lg bg-gray-100 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                    {actions.map((action, actionIdx) => (
                      <div
                        key={action.title}
                        className={classNames(
                          actionIdx === 0
                            ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                            : "",
                          actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                          actionIdx === actions.length - 2
                            ? "sm:rounded-bl-lg"
                            : "",
                          actionIdx === actions.length - 1
                            ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                            : "",
                          "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
                        )}
                      >
                        <div>
                          <span
                            className={classNames(
                              action.iconBackground,
                              action.iconForeground,
                              "rounded-lg inline-flex p-3 ring-4 ring-white"
                            )}
                          >
                            <action.icon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="mt-8">
                          <h3 className="text-lg font-medium">
                            <a
                              href={action.href}
                              className="focus:outline-none"
                            >
                              {/* Extend touch target to entire panel */}
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              {action.title}
                            </a>
                          </h3>
                          <p className="mt-2 text-sm text-gray-500">
                            {action.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
                  <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-bold text-gray-900">
                      Our core values
                    </h2>
                  </div>
                  <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt>

                          <p className="ml-9 text-lg leading-6 font-medium text-midBlue">
                            {feature.name}
                          </p>
                        </dt>
                        <dd className="mt-2 ml-9 text-base text-gray-500">
                          {feature.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div> */}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <StaticImage
          src="../images/hero-boost-2.jpg"
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          alt="Avert"
          placeholder="blurred"
          layout="fullWidth"
          width={1748}
          height={1327}
        />
      </div> */}
    </div>

    <Footer />
  </div>
);

export default About;
